import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"
// import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
// import Button from '@material-ui/core/Button';
import { CircularProgress } from "@material-ui/core"
// import ShowProperty from "../components/showproperty";
import { getSearchParams } from "gatsby-query-params"

const axios = require("axios")

const GetProperty = loadable(() => import("../components/getProperty"), {
  fallback: (
    <div className="page_loader">
      <CircularProgress color="inherit" />
    </div>
  ),
})

{
  /*let loader = <div style={{ padding:'10% 45%' }}><CircularProgress color="inherit" /></div>;*/
}

const Property = () => {
  const searchParams = getSearchParams()
  const [data, setData] = useState([])
  const [floorPlans, setFloorPlans] = useState([])
  const [gallery, setGallery] = useState([])
  const [spec, setSpec] = useState([])

  useEffect(() => {
    if (searchParams.id) {
      // make initial data call
      const url = `https://db.brownandkay-ejfw.temp-dns.com/wp-json/custom/v2/property?id=${searchParams.id}`

      axios
        .get(url, {
          headers: {},
        })
        .then(function(response) {
          if (response.data && response.data[0].floorplans) {
            setFloorPlans(response.data[0].floorplans)
          }

          // create array for slider
          if (response.data && response.data[0].gallery) {
            let gallery_array = []
            for (let i = 0; i < response.data[0].gallery.length; i++) {
              gallery_array.push(response.data[0].gallery[i][0])
            }
            setGallery(gallery_array)
          }

          setData(response.data)
          setSpec(response.data[0].specs)
        })
        .catch(function(error) {
          console.log("error" + error)
        })
    }
  }, [searchParams.id])

  const renderLoader = () => (
    <div style={{ padding: "50%" }}>
      <CircularProgress color="inherit" />
    </div>
  )

  return (
    <Layout>
      <SEO title={"Property"} />

      <Grid container>
        {data && (
          <GetProperty
            data={data}
            floorplans={floorPlans}
            gallery={gallery}
            spec={spec}
          />
        )}
      </Grid>
    </Layout>
  )
}

export default Property
